@use "@/styles/variables" as *;
@use "@/styles/utils" as *;
@use "../shared/styles.module.scss" as *;

.container {
    box-sizing: border-box;
    place-content: center;

    border: 2px solid #D6999F;
    background-color: $alert-bg-color;
}

.section {
    padding: 0 $space-xs;
}

.header {
    display: flex;
    justify-content: space-between;

    min-height: 50px;
    padding: $space-xs $alert-inline-padding 0;

    color: $alert-color;
}

.headerLeft,
.headerRight {
    display: flex;
    align-items: center;
    gap: 5px;
}

// There is a specificity issue when building the app
.alertIcon.alertIcon,
.closeIcon.closeIcon {
    --icon-size: #{$font-size-base};
    --padding: 3px;
    --dim: calc(var(--icon-size) + 2 * var(--padding));

    width: var(--dim);
    height: var(--dim);
    padding: var(--padding);
    flex-shrink: 0;

    @include md-min {
        --icon-size: #{$font-size-md};
        --padding: 5px;
    }
}

.title {
    font-family: $heading-font;
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    letter-spacing: $letter-spacing-headline;

    @include md-min {
        font-size: $font-size-md;
    }
}

.subtitle {
    color: $color-darker-gray;
    font-size: $font-size-xs;

    @include md-min {
        font-size: $font-size-sm;
    }
}
